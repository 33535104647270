// @ts-strict-ignore
import RichContentField from './RichContentField'
import { strippedHtmlLength } from './stripped-html-length'
import { Box, Heading, Image, Link, Text } from '@components/gassan-ui'
import { getAspectRatioImage } from '@lib/get-image-aspect-ratio'
import { getKontentImage } from '@lib/kontent'
import { CbTextAndImageOnBackgroundColor } from '@lib/kontent/models/CbTextAndImageOnBackgroundColor'
import { rem } from 'polished'
import { FC } from 'react'

type Props = CbTextAndImageOnBackgroundColor

const TextImageOnBgColor: FC<Props> = ({
  title,
  description,
  rich_content,
  desktop_image,
  mobile_image,
  link_text,
  link_url,
  order,
  large_image_variant,
  background_color,
}) => {
  const hasRichContent = rich_content.value ? strippedHtmlLength(rich_content.value) > 0 : false
  const orderTextImage = order.value !== undefined
  const isRegular = large_image_variant.value !== undefined
  const bg =
    background_color?.value[0]?.name === 'dark'
      ? 'shades.700'
      : background_color?.value[0]?.name === 'white'
        ? 'white'
        : background_color?.value[0]?.name === 'pampas'
          ? 'pampas'
          : 'pampasLight'
  const textColor = bg === 'shades.700' ? 'white' : 'initial'

  return (
    <Box as="section" bg={bg}>
      <Box
        width={{ _: !isRegular && '100%' }}
        maxWidth={{
          large: isRegular && rem(1450),
        }}
        m="0 auto"
        display={{ small: 'grid' }}
        gridTemplateColumns={{ small: '1fr 1fr' }}
        alignItems={{ small: 'center' }}
        py={{ large: isRegular && '5rem' }}
      >
        <Box
          gridColumn={{ small: orderTextImage ? 2 : 1 }}
          gridRow={{ small: 1 }}
          py={{
            _: isRegular && '1rem',
            small: isRegular && '2.5rem',
            large: 'initial',
          }}
          pr={{
            _: isRegular && '1rem',
            small: isRegular && orderTextImage ? '2rem' : 'initial',
          }}
          pl={{
            _: isRegular && '1rem',
            small: isRegular && orderTextImage ? 'initial' : '2rem',
          }}
        >
          <Image
            style={{ aspectRatio: `${getAspectRatioImage(mobile_image)}` }}
            className="block md:hidden"
            sources={[
              getKontentImage(mobile_image.value[0].url, { width: 600 }),
              getKontentImage(mobile_image.value[0].url, { width: 500 }),
            ]}
            alt={mobile_image.value[0].description}
          />
          <Image
            style={{ aspectRatio: `${getAspectRatioImage(desktop_image)}` }}
            className="hidden md:block"
            sources={[
              getKontentImage(desktop_image.value[0].url, { width: 600 }),
              getKontentImage(desktop_image.value[0].url, { width: 500 }),
              getKontentImage(desktop_image.value[0].url, { width: 1000 }),
              getKontentImage(desktop_image.value[0].url, { width: 1000 }),
            ]}
            alt={desktop_image.value[0].description}
          />
        </Box>
        <Box
          gridColumn={{ small: orderTextImage ? 1 : 2 }}
          gridRow={{ small: 1 }}
          px={{
            _: '1rem',
            small: '2rem',
          }}
          pt={{ _: '1.5rem', small: 'initial' }}
          pb={{ _: '2rem', small: 'initial' }}
        >
          <Box
            width="100%"
            maxWidth={'60rem'}
            display={{ _: 'contents', huge: 'block' }}
            ml={orderTextImage && 'auto'}
          >
            <Box
              maxWidth={{ large: '30rem' }}
              ml={{ large: !orderTextImage && isRegular && 'auto' }}
              m={{ large: !isRegular && '0 auto' }}
            >
              {title.value && (
                <Heading variant="h3" as="h3" color={textColor} mb={{ _: '1rem', large: '1.5rem' }}>
                  {title.value}
                </Heading>
              )}
              {hasRichContent ? (
                <RichContentField
                  {...rich_content}
                  textColor={bg === 'shades.700' ? 'white' : 'black'}
                />
              ) : (
                description.value && (
                  <Text style={{ whiteSpace: 'pre-line' }} mb="0" color={textColor}>
                    {description.value}
                  </Text>
                )
              )}
              {link_text.value && link_url.value && (
                <Box mt={{ _: '1.5rem', large: '2rem' }} display="inline-block">
                  <Link variant="brown" href={link_url.value}>
                    {link_text.value}
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TextImageOnBgColor

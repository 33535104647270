import { parse } from '@config/theme';
export const parent = parse({
  display: 'grid',
  textAlign: {
    _: 'center',
    small: 'left'
  },
  alignItems: 'center'
}, "psbbqs7");
export const contentContainer = parse({
  display: {
    _large: 'block'
  },
  ml: {
    medium: '12'
  }
}, "c6ztr83");
export const titleContainer = parse({}, "t10xjq1o");
export const descriptionContainer = parse({}, "d1wf176q");
export const uspContainer = "uh19anp";
export const mobileLinkContainer = parse({
  display: {
    _: 'block',
    small: 'none'
  },
  mt: '3'
}, "m1qtgwbm");
export const desktopLinkContainer = parse({
  display: {
    _: 'none',
    small: 'block'
  }
}, "d82a1s2");
export const imageContainer = parse({}, "i267okg");

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");
import { parse } from '@config/theme';
export const parent = parse({
  display: 'grid',
  mb: {
    _: '8',
    small: '16',
    large: '20'
  }
}, "p13xa6px");
export const imageParent = parse({}, "i1568qyc");
export const productsParent = parse({}, "p1i2na9c");
export const titleArea = parse({
  width: '100%'
}, "t15dzyv6");
export const itemGrid = parse({
  display: 'grid'
}, "i1axu9ng");
export const readMore = parse({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  mt: {
    _: '6',
    large: '8'
  }
});

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");
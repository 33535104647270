// @ts-strict-ignore
import RichContentField from './RichContentField'
import { strippedHtmlLength } from './stripped-html-length'
import { Box, Button, Heading, Span, Text, VideoPlayer, breakpoints } from '@components/gassan-ui'
import { useBreakpoint } from '@lib/hooks/useBreakpoint'
import { getKontentImage } from '@lib/kontent'
import { CbAutoplayVideo as CbAutoplayVideoProps } from '@lib/kontent/models/CbAutoplayVideo'
import React, { FC } from 'react'

type Props = CbAutoplayVideoProps

const AutoplayVideo: FC<Props> = ({
  title,
  description,
  button_url,
  button_text,
  border_color,
  desktop_video,
  mobile_video_height,
  mobile_video_width,
  desktop_video_width,
  desktop_video_height,
  mobile_preview_image,
  desktop_preview_image,
  mobile_video,
  display_wide_boxed,
  rich_annotation_text,
  title_tag,
  enable_activating_audio,
}) => {
  const allowActivatingAudio = enable_activating_audio?.value[0]
    ? enable_activating_audio.value[0].codename === 'yes'
    : false
  const hasRichContent = rich_annotation_text.value
    ? strippedHtmlLength(rich_annotation_text.value) > 0
    : false
  const isDesktop = useBreakpoint(breakpoints.large)
  const isBoxed = display_wide_boxed.value[0].codename === 'boxed_variant'
  const previewImage = isDesktop ? desktop_preview_image : mobile_preview_image
  const video = isDesktop ? desktop_video : mobile_video
  const width = isDesktop ? desktop_video_width.value : mobile_video_width.value
  const height = isDesktop ? desktop_video_height.value : mobile_video_height.value
  const bc = border_color.value[0]
    ? border_color.value[0].codename === 'dark'
      ? 'shades.700'
      : border_color.value[0].codename === 'white'
        ? 'white'
        : border_color.value[0].codename === 'pampas'
          ? 'pampas'
          : 'pampasLight'
    : null
  const headingType = title_tag.value[0] ? title_tag.value[0].codename : 'h2'

  return (
    <>
      <Box
        m={isBoxed && '0 auto'}
        width={isBoxed && '54rem'}
        maxWidth={isBoxed && { _: '100%', small: 'calc(100% - 4rem)', large: 'calc(100% - 2rem)' }}
        position="relative"
        p={bc && !isBoxed && { _: '1rem', small: '2rem', large: '4rem' }}
      >
        <VideoPlayer
          placeholderSrc={getKontentImage(previewImage.value[0]?.url, { width: 50 })}
          images={[
            getKontentImage(previewImage.value[0].url, { width: 600 }),
            getKontentImage(previewImage.value[0].url, { width: 900 }),
            getKontentImage(previewImage.value[0].url, { width: isBoxed ? 900 : 1000 }),
            getKontentImage(previewImage.value[0].url, { width: isBoxed ? 900 : 2000 }),
          ]}
          videoSrc={video.value[0]?.url}
          ratio={height / width}
          isFixedRatio
          playMode={allowActivatingAudio ? 'loop-muted-toggle-audio' : 'loop-muted'}
          maxWidth="initial"
        />
        {title && !isBoxed && (
          <Box
            zIndex={2}
            position="absolute"
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            top="0"
            left="0"
            borderColor={bc}
            borderWidth={bc && { _: '1rem', small: '2rem', large: '4rem' }}
            borderStyle="solid"
          >
            <Box
              textAlign="center"
              width={{ _: 'calc(100% - 2rem)' }}
              m="0 auto"
              maxWidth={{ large: '53.5rem' }}
            >
              <Heading variant="hXl" as={headingType as React.ElementType} mb="0" color="white">
                {title.value}
              </Heading>
              {description.value && description.value.length > 0 && (
                <Heading
                  variant="h3"
                  as="p"
                  display={{ _: 'none', large: 'block' }}
                  mt="1.5rem"
                  mb="0"
                  color="white"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {description.value}
                </Heading>
              )}
              {button_url.value && button_text.value && (
                <Box mt={{ _: '1rem', small: '1.5rem', large: '2rem' }} display="inline-block">
                  <Button variant="light" as="link" href={button_url.value}>
                    {button_text.value}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Box
        m={isBoxed && '0 auto'}
        width={isBoxed && '54rem'}
        maxWidth={isBoxed && { _: '100%', small: 'calc(100% - 4rem)', large: 'calc(100% - 2rem)' }}
      >
        {(hasRichContent ||
          (video.value[0]?.description && video.value[0].description.length > 0)) && (
          <Text
            pl={isBoxed ? { _: '1rem', small: '0' } : { _: '1rem', small: '2rem', large: '1rem' }}
            my={['0.5rem', '1rem']}
            variant="small"
            color="shades.500"
          >
            {hasRichContent ? (
              <RichContentField {...rich_annotation_text} textColor="grey" />
            ) : (
              <Span>{video.value[0].description}</Span>
            )}
          </Text>
        )}
      </Box>
    </>
  )
}

export default AutoplayVideo

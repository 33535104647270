import { parse } from '@config/theme';
export const parent = parse({
  position: 'relative',
  display: 'flex',
  alignItems: {
    _: 'flex-end',
    large: 'center'
  },
  mb: {
    _: '8',
    small: '16',
    large: '0'
  }
}, "p17dvrj5");
export const imageContainer = parse({
  width: '100%',
  position: 'absolute'
}, "i2vqqgn");
export const contentContainer = parse({
  position: 'relative',
  width: '100%'
}, "cs6h8r0");

require("./HomepageMyGassanSection.styles.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./HomepageMyGassanSection.styles.ts");
// @ts-strict-ignore
import * as styles from './HomepageAboutDiamonds.styles'
import { LinariaLink } from '@components/gassan-ui/LinariaLink'
import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading'
import { LinariaText } from '@components/gassan-ui/Typography/LinariaText'
import { Button, Container, Image, breakpoints } from '@gassan-ui'
import { cn } from '@lib/cn'
import { getKontentImage } from '@lib/kontent'
import { HomepageCbAboutDiamonds } from '@lib/kontent/models/HomepageCbAboutDiamonds'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import styled from 'styled-components'

const StyledImage = styled(Image)`
  @media screen and (max-width: ${breakpoints.medium}) and (orientation: landscape) {
    overflow: hidden;
    max-height: 70vh;
  }
`

const HomepageAboutDiamonds: FC<HomepageCbAboutDiamonds> = ({
  pretitle,
  title,
  description,
  desktop_image,
  mobile_image,
  shop_button_url,
  more_info_button_url,
}) => {
  const { t } = useTranslation('global')

  const sources = [
    getKontentImage(mobile_image.value[0].url, { width: 375 }),
    getKontentImage(mobile_image.value[0].url, { width: 750 }),
    getKontentImage(desktop_image.value[0].url, { width: 1440 }),
    getKontentImage(desktop_image.value[0].url, { width: 1920 }),
    getKontentImage(desktop_image.value[0].url, { width: 2560 }),
  ]

  return (
    <section className={styles.parent}>
      <div className={styles.imageParent}>
        <StyledImage
          className={cn('aspect-[8/5]', 'md:aspect-[5/3]', 'lg:aspect-[20/8]', 'huge:aspect-[4/1]')}
          sources={sources}
          placeholderSrc={getKontentImage(mobile_image.value[0].url, { width: 50 })}
          alt={mobile_image.value[0].description}
        />
      </div>
      <Container textAlign={['center', 'center', 'center', 'left']} maxWidth="90rem!important">
        <div className={styles.contentContainer}>
          <LinariaHeading variant="h5" as="h4" className={styles.heading}>
            {pretitle.value}
          </LinariaHeading>
          <LinariaHeading variant="h1" as="h2" mb="3">
            {title.value}
          </LinariaHeading>
          <LinariaText className={styles.text}>{description.value}</LinariaText>
          <div className={styles.grid}>
            {shop_button_url && shop_button_url.value !== '' && (
              <div className={styles.shopButtonContainer}>
                <Button
                  as="link"
                  href={shop_button_url.value}
                  className="min-w-full md:min-w-[auto]"
                  variant="dark"
                >
                  {t('shopGassan121')}
                </Button>
              </div>
            )}
            {more_info_button_url && more_info_button_url.value !== '' && (
              <div>
                <LinariaLink href={more_info_button_url.value}>
                  {t('moreAboutDiamonds')}
                </LinariaLink>
              </div>
            )}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default HomepageAboutDiamonds

import { parse } from '@config/theme';
export const parent = parse({
  position: 'relative',
  bg: {
    _: 'white',
    small: 'shade50',
    large: 'white'
  }
}, "pkcj7yl");
export const imageParent = "izqh4rq";
export const contentContainer = parse({
  bg: 'pampas',
  position: {
    _: 'relative',
    large: 'absolute'
  }
}, "c15lpg6o");
export const heading = "he8u9m3";
export const text = "tit528j";
export const grid = parse({
  display: 'grid',
  alignItems: 'center',
  textAlign: 'center',
  mt: {
    _: '4',
    small: '8',
    medium: '12'
  }
}, "g3vkw5s");
export const shopButtonContainer = parse({
  display: 'flex',
  justifyContent: {
    _: 'center',
    large: 'flex-start'
  }
}, "s19h2xq");

require("./HomepageAboutDiamonds.styles.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./HomepageAboutDiamonds.styles.ts");
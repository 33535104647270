// @ts-strict-ignore
import * as styles from './HomepageMyGassanSection.styles'
import RichContentField from '@components/_content-page/RichContentField'
import { Box, Button, Container, Image, LinariaBox } from '@components/gassan-ui'
import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading'
import { getKontentImage } from '@lib/kontent'
import { HomepageCbMygassanMembership } from '@lib/kontent/models/HomepageCbMygassanMembership'
import Link from 'next/link'
import { rem } from 'polished'
import { FC } from 'react'

type HomepageMyGassanSectionProps = HomepageCbMygassanMembership

export const HomepageMyGassanSection: FC<HomepageMyGassanSectionProps> = ({
  title,
  rich_content,
  button_text,
  button_url,
  content_background_color,
  mobile_image,
  desktop_image,
}) => {
  const bg =
    content_background_color?.value[0]?.name === 'dark'
      ? 'shades.700'
      : content_background_color?.value[0]?.name === 'white'
        ? 'white'
        : content_background_color?.value[0]?.name === 'pampas'
          ? 'pampas'
          : 'pampasLight'
  const textColor = bg === 'shades.700' ? 'white' : 'inherit'
  return (
    <div className={styles.parent}>
      <div className={styles.imageContainer}>
        <Image
          className="h-full w-full"
          sources={[
            getKontentImage(mobile_image.value[0].url, { width: 600 }),
            getKontentImage(mobile_image.value[0].url, { width: 500 }),
            getKontentImage(desktop_image.value[0].url, { width: 1400 }),
            getKontentImage(desktop_image.value[0].url, { width: 2000 }),
          ]}
          placeholder={getKontentImage(mobile_image.value[0].url, { width: 50 })}
          alt={desktop_image.value[0].description}
        />
      </div>
      <div className={styles.contentContainer}>
        <Container maxWidth={rem(1450)}>
          <Box
            bg={bg}
            p={{ _: '1.5rem', small: '3rem', xlarge: '4rem' }}
            maxWidth={{ _: '', small: '37rem' }}
            ml={{
              _: 'auto',
              large: 'right',
            }}
            mr={{ _: 'auto', large: 'initial' }}
          >
            {title.value && (
              <LinariaHeading variant="h2" as="h2" mb={{ _: '4', large: '6' }} color={textColor}>
                {title.value}
              </LinariaHeading>
            )}
            <RichContentField
              {...rich_content}
              textColor={bg === 'shades.700' ? 'white' : 'black'}
            />
            {button_text && button_url && (
              <LinariaBox mt={{ _: '6', large: '8' }} display="inline-block">
                <Link href={button_url.value} passHref>
                  <Button variant="dark" as="link">
                    {button_text.value}
                  </Button>
                </Link>
              </LinariaBox>
            )}
          </Box>
        </Container>
      </div>
    </div>
  )
}

import FeaturedImage from './FeaturedImage'
import Usps from './Usps'
import * as styles from './styles'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading'
import { Button, Container, LinariaBox, Link, Text } from '@gassan-ui'
import { HomepageCbBookATour } from '@lib/kontent/models/HomepageCbBookATour'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type Props = HomepageCbBookATour

const HomepageBookATour: FC<Props> = ({ title, description, image }) => {
  const { t } = useTranslation('other')
  return (
    <LinariaBox bg={{ _: 'white', small: 'shade50' }}>
      <Container className={styles.parent}>
        <div className={styles.contentContainer}>
          <div className={styles.titleContainer}>
            <LinariaHeading variant="h2" as="h2" mb={{ _: '3', large: '4' }}>
              {title.value}
            </LinariaHeading>
          </div>
          <div className={styles.descriptionContainer}>
            <Text>{description.value}</Text>
          </div>
          <div className={styles.uspContainer}>
            <Usps />
          </div>
          <div className={styles.mobileLinkContainer}>
            <LocalizedLink href="/tours">
              <Button variant="dark" className="min-w-full">
                {t('viewAllTours')}
              </Button>
            </LocalizedLink>
          </div>
          <div className={styles.desktopLinkContainer}>
            <LocalizedLink href="/tours">
              <Link pl="0!important">{t('viewAllTours')}</Link>
            </LocalizedLink>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <FeaturedImage alt={title.value} src={image.value[0].url} />
        </div>
      </Container>
    </LinariaBox>
  )
}

export default HomepageBookATour

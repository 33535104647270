import { LinkImage } from '@components/LinkImage';
import { Container } from '@components/gassan-ui';
import { parse } from '@config/theme';
import { CbSingleImageFourVerticalLinkImages } from '@lib/kontent/models/CbSingleImageFourVerticalLinkImages';
import { FC } from 'react';
type Props = {
  items: CbSingleImageFourVerticalLinkImages[];
};
const FourVerticalLinkImages: FC<Props> = ({
  items
}) => {
  return <Container mb={{
    _: '2rem',
    small: '4rem',
    large: '5rem'
  }}>
      <div className={grid}>
        {items.map(item => <LinkImage variant="vertical" title={item.text.value} href={item.url.value} imageSource={item.image.value[0].url} />)}
      </div>
    </Container>;
};
const grid = parse({
  display: 'grid'
}, "g1dzeewd");
export default FourVerticalLinkImages;

require("./FourVerticalLinkImages.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./FourVerticalLinkImages.tsx");
import { parse } from '@config/theme';
export const content = parse({
  position: 'relative',
  bg: 'shade100'
}, "cveng0d");
export const textStyles = "t183azfp";
export const text = "tagt3d8";
export const hoverText = "hq2h35b";
export const anchor = "aemqpyx";
export const imageContainer = parse({
  bg: 'shade100'
});
export const grid = parse({
  display: 'grid'
}, "g1po9n6j");

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");
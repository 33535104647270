import { parse } from '@config/theme';
export const container = parse({
  position: 'relative'
}, "cell1kw");
export const imageContainer = "iqxpt37";
export const buttonContainer = parse({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center'
}, "b1jg1tfp");

require("./HomepageCtaBanner.styles.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./HomepageCtaBanner.styles.ts");
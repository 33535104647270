import * as styles from './styles'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading'
import { linkStyle } from '@components/gassan-ui/Typography/linariaStyles'
import { Container, Image } from '@gassan-ui'
import { cn } from '@lib/cn'
import { getKontentImage } from '@lib/kontent'
import { CategoryPage } from '@lib/kontent/models/CategoryPage'
import { HomepageCbCategories } from '@lib/kontent/models/HomepageCbCategories'
import { cx } from '@linaria/core'
import { FC } from 'react'

type Props = HomepageCbCategories & {
  items: CategoryPage[]
}

const ContentBlockShopCategories: FC<Props> = ({ title, items }) => {
  return (
    <Container>
      <LinariaHeading variant="h2" as="h2" mb={{ _: '6', medium: '8', xlarge: '10' }}>
        {title.value}
      </LinariaHeading>
      <div className={styles.grid}>
        {items.map((item, index) => (
          <LocalizedLink href={`/${item.url_slug.value}`} key={index}>
            <a className={styles.anchor} title={item.title.value}>
              <div className={styles.imageContainer}>
                <Image
                  placeholderSrc={getKontentImage(item.image.value[0]?.url, { width: 40 })}
                  sources={[getKontentImage(item.image.value[0]?.url, { width: 320 })]}
                  alt="Alt text"
                  className={cn('aspect-[5/6]', 'sm:aspect-[5/7]')}
                  loading="lazy"
                />
              </div>
              <div className={styles.content} data-content>
                <span className={cx(styles.text, styles.textStyles, linkStyle)} data-text>
                  {item.title.value}
                </span>
                <span
                  className={cx(styles.hoverText, styles.textStyles, linkStyle)}
                  data-hover-text
                >
                  {item.title.value}
                </span>
              </div>
            </a>
          </LocalizedLink>
        ))}
      </div>
    </Container>
  )
}

export default ContentBlockShopCategories

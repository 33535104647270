// @ts-strict-ignore
import * as styles from './HomepageCtaBanner.styles'
import { Button, Image } from '@gassan-ui'
import { getKontentImage } from '@lib/kontent'
import { HomepageCbCtaBanner } from '@lib/kontent/models/HomepageCbCtaBanner'
import { FC } from 'react'

type Props = HomepageCbCtaBanner

const HomepageCtaBanner: FC<Props> = ({
  cta_banner__button_text,
  cta_banner__button_url,
  cta_banner__mobile_image,
  cta_banner__desktop_image,
}) => {
  const sources = [
    getKontentImage(cta_banner__mobile_image.value[0].url, { width: 640 }),
    getKontentImage(cta_banner__mobile_image.value[0].url, { width: 768 }),
    getKontentImage(cta_banner__desktop_image.value[0].url, { width: 1024 }),
    getKontentImage(cta_banner__desktop_image.value[0].url, { width: 1344 }),
    getKontentImage(cta_banner__desktop_image.value[0].url, { width: 1444 }),
    getKontentImage(cta_banner__desktop_image.value[0].url, { width: 1635 }),
  ]

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Image
          sources={sources}
          className="absolute left-0 top-0 h-full w-full"
          placeholderSrc={getKontentImage(cta_banner__mobile_image.value[0].url, { width: 50 })}
          alt={cta_banner__mobile_image.value[0].description}
        />
      </div>
      {cta_banner__button_url.value && cta_banner__button_text.value && (
        <div className={styles.buttonContainer}>
          <Button
            className="min-w-full sm:min-w-[inherit]"
            as="link"
            href={cta_banner__button_url.value}
          >
            {cta_banner__button_text.value}
          </Button>
        </div>
      )}
    </div>
  )
}

export default HomepageCtaBanner

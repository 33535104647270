// @ts-strict-ignore
import RichContentField from './RichContentField'
import { strippedHtmlLength } from './stripped-html-length'
import { Box, Container, Heading, Image, Link } from '@components/gassan-ui'
import { getKontentImage } from '@lib/kontent'
import { CbTextInRectangleOnImageBackground } from '@lib/kontent/models/CbTextInRectangleOnImageBackground'
import { rem } from 'polished'
import { FC } from 'react'

type Props = Omit<CbTextInRectangleOnImageBackground, 'description'>

const TextInRectangleOnImageBackground: FC<Props> = ({
  title,
  rich_content,
  desktop_image,
  mobile_image,
  link_text,
  link_url,
  content_alignment,
  background_color,
}) => {
  const hasRichContent = rich_content.value ? strippedHtmlLength(rich_content.value) > 0 : false
  const bg =
    background_color?.value[0]?.name === 'dark'
      ? 'shades.700'
      : background_color?.value[0]?.name === 'white'
        ? 'white'
        : background_color?.value[0]?.name === 'pampas'
          ? 'pampas'
          : 'pampasLight'
  const textColor = bg === 'shades.700' ? 'white' : 'initial'
  return (
    <Box
      display="flex"
      alignItems={{ _: 'flex-end', large: 'center' }}
      position="relative"
      mb={{ _: '2rem', small: '4rem', large: '0' }}
      pt={{ _: '55%', small: '50%', large: '5rem', huge: '9.5rem' }}
      pb={{ large: '5rem', huge: '9.5rem' }}
    >
      <Box
        zIndex={-1}
        position="absolute"
        height={{ _: '55%', small: '80%', large: '100%' }}
        top="0"
        width="100%"
      >
        <Image
          className="h-full w-full"
          sources={[
            getKontentImage(mobile_image.value[0].url, { width: 600 }),
            getKontentImage(mobile_image.value[0].url, { width: 500 }),
            getKontentImage(desktop_image.value[0].url, { width: 1400 }),
            getKontentImage(desktop_image.value[0].url, { width: 2000 }),
          ]}
          placeholder={getKontentImage(mobile_image.value[0].url, { width: 50 })}
          alt={desktop_image.value[0].description}
        />
      </Box>
      <Box zIndex={2} position="relative" width="100%">
        <Container maxWidth={rem(1450)}>
          <Box
            bg={bg}
            p={{ _: '1.5rem', small: '3rem', xlarge: '4rem' }}
            maxWidth={{ _: '', small: '37rem' }}
            ml={{
              _: 'auto',
              large: content_alignment.value[0].codename === 'right' ? 'auto' : 'initial',
            }}
            mr={{ _: 'auto', large: 'initial' }}
          >
            {title.value && (
              <Heading variant="h2" as="h2" mb={{ _: '1rem', large: '1.5rem' }} color={textColor}>
                {title.value}
              </Heading>
            )}
            {hasRichContent && (
              <RichContentField
                {...rich_content}
                textColor={bg === 'shades.700' ? 'white' : 'black'}
              />
            )}
            {link_text && link_url && (
              <Box mt={{ _: '1.5rem', large: '2rem' }} display="inline-block">
                <Link variant="brown" href={link_url.value}>
                  {link_text.value}
                </Link>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default TextInRectangleOnImageBackground
